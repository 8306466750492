.dropzone {
    border: 0;
    background-color: #f3f3f3;
    border-radius: 2px;

    .dz-preview {
        .dz-image {
            border-radius: 2px !important;
            border: 4px solid #fff;
            box-shadow: @card-shadow;
        }

        .dz-remove {
            position: absolute;
            top: -3px;
            right: -4px;
            z-index: 20;
            font-size: 0;
            width: 20px;
            height: 20px;
            background-color: @m-red;
            border-radius: 50%;

            &:hover {
                background-color: darken(@m-red, 5%);
                text-decoration: none;
            }

            &:before {
                content: '\f136';
                font-size: 11px;
                font-family: @font-icon-md;
                color: #fff;
                font-weight: bold;
                line-height: 20px;
            }
        }
    }

    .dz-message {
        span {
            font-size: 20px;
            color: #b5b5b5;
            text-shadow: 0 1px 1px #fff;
            border: 2px solid #e4e4e4;
            padding: 15px 20px;
            display: inline-block;
            border-radius: 5px;
            .transition(all);
            .transition-duration(300ms);

            &:before {
                content: '\f21e';
                font-family: @font-icon-md;
                margin: -6px 10px 0 0;
                font-size: 25px;
                display: inline-block;
                position: relative;
                top: 2px;
            }
        }
    }

    &:hover {
        .dz-message {
            span {
                border-color: darken(#e4e4e4, 5%);
                color: darken(#b5b5b5, 5%);
            }
        }
    }

}