/*--------------------
  Roboto Light
---------------------*/
.font-face(roboto, 'Roboto-Light-webfont', 300, normal);


/*--------------------
  Roboto Regular
---------------------*/
.font-face(roboto, 'Roboto-Regular-webfont', 400, normal);


/*--------------------
  Roboto Medium
---------------------*/
.font-face(roboto, 'Roboto-Medium-webfont', 500, normal);


/*--------------------
  Roboto Bold
---------------------*/
.font-face(roboto, 'Roboto-Bold-webfont', 700, normal);


/*--------------------
  Satisfy
---------------------*/
.font-face(satisfy, 'satisfy', 400, normal);

@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSansCyrl-500.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-500.woff2") format("truetype"), url("../fonts/MuseoSansCyrl-500.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSansCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-700.woff2") format("truetype"), url("../fonts/MuseoSansCyrl-700.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Museo";
  src: url("../fonts/MuseoCyrl-500.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoCyrl-500.woff2") format("truetype"), url("../fonts/MuseoCyrl-500.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Museo";
  src: url("../fonts/MuseoCyrl-500Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoCyrl-500Italic.woff2") format("truetype"), url("../fonts/MuseoCyrl-500Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Museo";
  src: url("../fonts/MuseoCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoCyrl-700.woff2") format("truetype"), url("../fonts/MuseoCyrl-700.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Museo";
  src: url("../fonts/MuseoCyrl-900.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoCyrl-900.woff2") format("truetype"), url("../fonts/MuseoCyrl-900.woff") format("woff");
  font-weight: 900;
}